
import { mapActions } from 'vuex'
import VButton from '@/components/VButton'
import DaySelect from '@/components/Forms/Inputs/DaySelect'
import MonthSelect from '@/components/Forms/Inputs/MonthSelect'
import YearSelect from '@/components/Forms/Inputs/YearSelect'
export default {
  layout: 'login',
  components: { VButton, DaySelect, MonthSelect, YearSelect },
  data () {
    return {
      customer: null,
      email: null,
      geburtstag: null,
      firstType: null,
      secondType: null,
      vertragsnummer: null,
      geburtstagMonth: null,
      geburtstagDay: null,
      geburtstagYear: null,
      medium: null,
      error: null,
      loading: false,
      path: null
    }
  },
  computed: {
    inputClasses () {
      return [
        'w-full',
        'block',
        'px-3',
        'py-2',
        'border',
        'border-gray-300',
        'rounded-md',
        'leading-5',
        'placeholder-gray-400',
        'transition',
        'duration-150',
        'ease-in-out',
        'focus:outline-none',
        'focus:shadow-outline-blue',
        'focus:border-blue-300'
      ]
    },
    selectClasses () {
      return [
        'appearance-none block w-full px-3 py-1.5 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out'
      ]
    }
  },
  watch: {
    firstType () {
      this.customer = null
      this.vertragsnummer = null
    },
    secondType () {
      this.email = null
      this.geburtstag = null
    }
  },
  mounted () {
    this.$webID()
    this.$ident()

    const { query } = this.$route

    if (query?.cff) {
      localStorage.setItem('cff', true)
    }

    const credentials = this.$loginWithCredentials()

    if (credentials) {
      this.customer = credentials.customer
      this.vertragsnummer = credentials.vertragsnummer
      this.geburtstag = credentials.geburtstag
      this.email = credentials.email
      this.medium = credentials.medium
      this.path = credentials.path
      this.login()
    }

    const token = this.$loginWithToken()

    if (token) {
      this.customer = token.customer
      this.email = token.email
      this.medium = token.medium
      this.path = token.path
      this.login()
    }
  },
  methods: {
    ...mapActions({
      setPath: 'customer/setPath'
    }),
    loginWithForm () {
      if (this.loading) { return }

      if (this.secondType === 'geburtstag') {
        this.geburtstag = `${this.geburtstagYear}-${this.geburtstagMonth < 10 ? '0' : ''}${this.geburtstagMonth}-${this.geburtstagDay < 10 ? '0' : ''}${this.geburtstagDay}`
      }

      this.login()
    },
    async login () {
      if (this.loading) { return }

      // Wenn customer mehr als 9 Zeichen lang ist, als vertragsnummer behandeln
      if (this.customer && this.customer.length >= 9) {
        this.vertragsnummer = this.customer
        this.customer = null
      }

      this.loading = true

      try {
        await this.$auth.loginWith('local', {
          data: {
            customer: this.customer,
            email: this.email,
            geburtstag: this.geburtstag,
            vertragsnummer: this.vertragsnummer,
            ...this.medium ? { medium: this.medium } : {}
          }
        })

        this.$alert('Erfolgreich angemeldet.', false, 1500)
        if (this.path) {
          this.setPath(this.path)
          this.$router.push(this.path)
        } else {
          this.$router.push('/home')
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$alert('Fehler beim Anmelden, evtl. falsche Zugangsdaten.', true, undefined, 'shield-exclamation')
      }
    }
  }
}
