
export default {
  name: 'DaySelect',
  props: {
    value: {
      type: Number,
      default: null
    },
    classes: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    change ({ target }) {
      this.$emit('input', target.value)
    }
  }
}
