
export default {
  name: 'MonthSelect',
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    classes: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ]
    }
  },
  methods: {
    change ({ target }) {
      this.$emit('input', target.value)
    }
  }
}
