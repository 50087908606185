
export default {
  name: 'YearSelect',
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    classes: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    maxYear: {
      type: Number,
      default () {
        const d = new Date()
        return d.getFullYear()
      }
    }
  },
  data () {
    return {
      date: new Date()
    }
  },
  methods: {
    change ({ target }) {
      this.$emit('input', target.value)
    }
  }
}
